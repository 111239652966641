<template>
  <div class="home">
    <h1>Xin chào, {{UserData.Name}}</h1>
  </div>
</template>

<script>
import StaffHelper from '@/modules/staff.js'
import '@/main.js'
import {mapGetters} from "vuex";
const StaffService = new StaffHelper();
export default {
  name: 'Home',
  components: {
  },
  computed: {
    ...mapGetters(['Token','UserData','URL'])
  },
  methods:{
    Auth:StaffService.Auth,
    doCheckAuth: StaffService.doCheckAuth,
  },
  mounted(){
    this.doCheckAuth(()=>{
      if(this.$route.query.url){
        //this.$store.commit('doCallback',this.$route.query.url);
        window.location = this.$route.query.url+'?token='+this.Token
      }
    })

  }
}
</script>
