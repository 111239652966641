<template>
  <div id="app">
    <navbar />
    <router-view/>
  </div>
</template>
<script>
import navbar from '@/components/navbar.vue';
export default{
  components: {
    navbar,
  }
}
</script>
<style>
#app {
  font-family: "Fire Code", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
