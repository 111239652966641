<template>
  <div class="hero is-info is-fullheight">
    <b-loading :is-full-page="true" v-model="isLoading" />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div class="box">
              <b-field label="Tài khoản"
                :type="Errors.Login.status"
                :message="Errors.Login.message"
              >
                <b-input
                    v-model="Data.Login"
                    placeholder="Tài khoản"
                    icon="account"
                    rounded
                />
              </b-field>
              <b-field label="Mật khẩu"
                       :type="Errors.Password.status"
                       :message="Errors.Password.message"
              >
                <b-input
                    v-model="Data.Password"
                    placeholder="Mật khẩu"
                    icon="lock"
                    class="block"
                    type="password"
                    rounded
                    password-reveal
                />
              </b-field>
              <b-button
                  size="is-medium"
                  type="is-info"
                  icon-right="login"
                  rounded
                  :loading="isLoading"
                  :disabled="isLoading"
                  @click="doLogin"
              >
                Đăng nhập
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaffHelper from '@/modules/staff.js'
import '@/main.js'
const StaffService = new StaffHelper();
export default {
  name: "Login",
  data(){
    return {
      isLoading: false,
       Data:{
         Login:null,
         Password: null,
      },
      Errors:{
         Login:{
           status:null,
           message:'',
         },
        Password:{
          status:null,
          message:'',
        }
      }
    }
  },
  methods:{
    doLogin:StaffService.doLogin,
    Login:StaffService.Login,
  }
}
</script>

<style scoped>

</style>