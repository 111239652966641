export const Errors = ({message,status="is-danger"}) =>{
	message = message || null
	status = (!message) ? null:status
	const result = {
		status:status,
		message:message,
	}
	return result
}
export default {
	Errors,
}