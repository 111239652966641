import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import {getStore, setStore, removeItem} from '@/helpers/storages.js';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Token: getStore('Tom-Auth-Token'),
    Url: undefined,
    UserData:{

    }
  },
  mutations: {
    doLogin(state, login){
      state.Token = login
      setStore('Tom-Auth-Token', login)
    },
    doLogout(state) {
      state.Token = undefined
      removeItem('Tom-Auth-Token')
      router.push('/login')
    },
    doSetUserData(state,data){
      state.UserData = Object.assign({}, data)
    },
    doCallback(state, url){
      state.Url = url
    }
  },
  getters:{
    Token(state){
      return state.Token
    },
    URL(state){
      return state.Url
    },
    UserData(state){
      return state.UserData
    }
  }
})
