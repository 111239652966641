import Strings from '@/helpers/strings.js'
const API_ENDPOINT = 'https://api.tomorder.jp/v1';
//const API_ENDPOINT = 'http://127.0.0.1:21222/v1';
export default class StaffService {
	doCheckAuth(callback){
		this.isLoading = true
		this.Auth().then(resp=>{
			const {data} = resp;
			if(data){
				this.$store.commit('doSetUserData',data);
				if(typeof callback === 'function'){
					callback();
				}
			}else{
				this.$store.commit('doLogout');
				this.$buefy.toast.open({
					message: 'Không kiểm tra được thông tin đăng nhập. Thử đăng nhập lại !!!',
					type: 'is-warning'
				})
				setTimeout(()=>{
					this.$router.push('/login')
				},100)
			}
		}).catch(e=>{
			this.$store.commit('doLogout');
			this.$buefy.toast.open({
				message: 'Không kiểm tra được thông tin đăng nhập. Thử đăng nhập lại !!!',
				type: 'is-warning'
			})
			setTimeout(()=>{
				this.$router.push('/login')
			},100)
		})
		.finally(() => {
			this.isLoading = false
		})
	}
	doLogin() {
		const {Login, Password} = this.Data;
		if (!Login) {
			this.Errors.Login = Strings.Errors({error:'Tài khoản không hợp lệ !'})
			return;
		}else{
			this.Errors.Login = Strings.Errors({message:''})
		}
		if (!Password) {
			this.Errors.Password = Strings.Errors({message:'Mật khẩu không hợp lệ !'})
			return;
		}else{
			this.Errors.Password = Strings.Errors({message:''})
		}
		this.isLoading = true
		this.Login(this.Data).then(resp=>{
			const {error,token} = resp;
			if(error){
				this.Errors.Login = Strings.Errors({message:error})
				this.Errors.Password = Strings.Errors({message:error})
				return;
			}
			if(token){
				this.$store.commit('doLogin',token);
				this.$buefy.toast.open({
					message: 'Đã đăng nhập thành công. Hệ thống sẽ tự chuyển !!!',
					type: 'is-success'
				})
				const {url} = this.$route.query;
				setTimeout(()=>{
					if(url) window.location = url+'?token='+token
					else this.$router.push('/')
				},100)
			}
		}).catch(e=>{
			this.$buefy.toast.open({
				message: 'Có lỗi trong quá trình đăng nhập. Hãy thử lại !!!',
				type: 'is-warning'
			})
		}).finally(()=>{
			this.isLoading = false
		})
	}
	Login({Login, Password}){
		return fetch(`${API_ENDPOINT}/staff/login`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({Login, Password})
		}).then(resp => {
			return resp.json();
		});
	}
	Auth(){
		if (!this.Token) return Promise.reject('No Token');
		return fetch(`${API_ENDPOINT}/staff/auth`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.Token,
			},
		}).then(resp => {
			return resp.json();
		});
	}
}